import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import { internalRoutePaths } from '../../../../../../../../../../config/config';
import { confirmGreen } from '../../../../../../../../../../config/colors';

import { WowListItemSmallElementText } from '../../../../../../../../../../components/wowlist/ItemSmall';
import { WowDisplayFlex } from '../../../../../../../../../../components_new/WowBasicElements';
import ModalConfirm from '../../../../../../../../../../components_new/ModalConfirm';

import {
  IconTask,
  IconClose,
  Card,
  Image,
  CardCenterContent,
  CardRightContent,
  IssueStatistic,
  GroupText,
  InfoTextWrapper,
  Circle,
  TypeText,
  TitleText,
  IconCloseWrapper,
  IconAsset,
} from '../styles';
import useGoToAssetsList from '../../../../../../../../Assets/hooks/useGoToList';
import { useDispatch } from 'react-redux';
import { updateCreateFormUnsavedData } from '../../../../../../../slice';

const Item = (props) => {
  const {
    disableNavigate,
    disableDelete,
    item,
    isAssetGroup,
    modalClasses,
    showStatistics,
  } = props;

  const { group, system, name, attachments, statistics, status, workspace } =
    item;
  const hasImages = attachments?.find((item) => item.media_type === 'picture');

  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { setValue, getValues } = useFormContext();

  const assetRoute = internalRoutePaths.ASSETS_ID.replace(':id', item.id);

  const handleDelete = () => {
    setDeleteModal(false);
    setValue('asset_id', '', { shouldDirty: true });
    setValue('asset', null, { shouldDirty: true });
    setValue('asset_group_id', '', { shouldDirty: true });
    setValue('asset_group', null, { shouldDirty: true });
  };

  const { handleGoToList } = useGoToAssetsList();

  return (
    <>
      <Card
        onClick={(e) => {
          if (disableNavigate) return;
          const target = e.target;
          if (target && !target.classList.contains('icon-close')) {
            if (history.location.pathname.includes('/add')) {
              dispatch(updateCreateFormUnsavedData(getValues()));
            }
            if (isAssetGroup) {
              handleGoToList({
                asset_group_ids_params: [{ id: item.id, name: item.name }],
              });
            } else {
              history.push({
                pathname: assetRoute,
                state: { fromPath: history?.location.pathname },
              });
            }
          }
        }}
        data-testid="assetSectionCard"
      >
        <IconCloseWrapper>
          {!disableDelete && (
            <IconClose
              style={{ fontSize: 12 }}
              onClick={() => setDeleteModal(true)}
              data-testid="assetSectionCardCloseButton"
            />
          )}
        </IconCloseWrapper>
        {hasImages ? (
          <Image
            alt={hasImages.original_filename}
            src={hasImages.web_mob_thumbnail_url}
          />
        ) : null}

        <CardCenterContent>
          <TitleText>{name}</TitleText>
          <InfoTextWrapper>
            {group?.name ? (
              <>
                <GroupText>{group.name}</GroupText>
                <Circle />
              </>
            ) : null}

            {system?.name ? <TypeText>{system.name}</TypeText> : null}
            {workspace?.name && isAssetGroup ? (
              <GroupText>{workspace.name}</GroupText>
            ) : (
              ''
            )}
          </InfoTextWrapper>
          {showStatistics ? (
            <div>
              <IssueStatistic>
                {isAssetGroup ? <IconAsset /> : <IconTask />}{' '}
                {isAssetGroup
                  ? statistics?.assets_in_group ?? 0
                  : statistics?.asset_issues ?? 0}
              </IssueStatistic>
            </div>
          ) : null}
        </CardCenterContent>
        <CardRightContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          {status === 'ACTIVE' && (
            <WowListItemSmallElementText>
              <WowDisplayFlex>
                <div
                  style={{
                    color: confirmGreen,
                    fontSize: 12,
                  }}
                >
                  <FormattedMessage id="active" defaultMessage="Active" />
                </div>
              </WowDisplayFlex>
            </WowListItemSmallElementText>
          )}
        </CardRightContent>
      </Card>
      {deleteModal && (
        <ModalConfirm
          titleKey={isAssetGroup ? 'remove_asset_group' : 'remove_asset'}
          isOpen={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleAction={handleDelete}
          actionButtonColor="red"
          actionType="yes_remove"
          intlValues={{
            messageKey: isAssetGroup
              ? 'remove_asset_group_from_issue'
              : 'remove_asset_from_issue',
            [isAssetGroup ? 'asset_group_name' : 'asset_name']: name,
          }}
          modalClasses={modalClasses?.delete}
        />
      )}
    </>
  );
};

export default Item;
